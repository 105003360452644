import './App.css';
import React,{useState} from 'react'
import NavBar from './components/NavBar';
import News from './components/News';
import {BrowserRouter as Router,Route, Routes} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
 const App= ()=> {
  let pageSize=15;
  let apiKey = process.env.REACT_APP_NEWSMONKEY_API_KEY;
  const [progress,setProgress]=useState(10);
  
    return (
      <>
        <Router>
        <NavBar/>
        <LoadingBar
        height={4}
        color='#f11946'
        progress={progress}
      />
        <div className="container">
        
          <Routes>
                <Route exact element={<News setProgress={setProgress} apiKey={apiKey} key = "general" country="in" pageSize = {pageSize} />} path="/" />
                <Route exact element={<News setProgress={setProgress} apiKey={apiKey} key = "business" country="in" pageSize = {pageSize} category="business"/>} path="/business" />
                <Route exact element={<News setProgress={setProgress} apiKey={apiKey} key = "entertainment" country="in" pageSize = {pageSize} category="entertainment"/>} path="/entertainment" />
                <Route exact element={<News setProgress={setProgress} apiKey={apiKey} key = "general" country="in" pageSize = {pageSize} category="general"/>} path="/general" />
                <Route exact element={<News setProgress={setProgress} apiKey={apiKey} key = "health" country="in" pageSize = {pageSize} category="health"/>} path="/health" />
                <Route exact element={<News setProgress={setProgress} apiKey={apiKey} key = "science" country="in" pageSize = {pageSize} category="science"/>} path="/science" />
                <Route exact element={<News setProgress={setProgress} apiKey={apiKey} key = "sports" country="in" pageSize = {pageSize} category="sports"/>} path="/sports" />
                <Route exact element={<News setProgress={setProgress} apiKey={apiKey} key = "technology" country="in" pageSize = {pageSize} category="technology"/>} path="/technology" />                
          </Routes>
        </div>
        </Router>
      </>
    )
  
}
export default  App;