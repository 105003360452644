import React from 'react'

const NewsItem =(props)=> {
    let {title,description,imageUrl,newsUrl,author,publishedAt,source} = props;
    return (
      <div>
        <div className="card">
          <div className="d-flex position-absolute align-self-end">
              <span  className="badge rounded-pill bg-danger">
                  {source}
                <span className="visually-hidden">unread messages</span>
              </span></div>
            <img src={imageUrl?imageUrl:"https://image.cnbcfm.com/api/v1/image/107148877-1668001185290-gettyimages-1244449132-US-NEW_YORK-STOCK_MARKET-FALL.jpeg"} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <p className="card-text">{description}</p>
              <p className="card-text"><small className="text-muted">By {author?author:'Unknown'} on {new Date(publishedAt).toGMTString()}</small></p>
              <a rel="noreferrer" href={newsUrl} target="_blank" className="btn btn-sm btn-dark">Read more</a>
            </div>
          </div>
      </div>
    )
  
}

export default NewsItem
