import React, { useEffect,useState } from "react";
import NewsItem from "./NewsItem";
import Spinner from "./Spinner";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import newsData from '../sampleNewsResponse.json';
const News = (props)=>{
  
  const [articles,setArticles] = useState([]);
  const [loading,setLoading] = useState(true);
  const [page,setPage] = useState(1);
  const [totalResults,setTotalResults] = useState(0);
  // document.title = `${capitalizeFirstLetter(
  //   props.category
  // )} - NewsMonkey`;
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const topLoaderFunction=(actionType,progress)=>{
    if(actionType===0){
      props.setProgress(progress);
    }
  }
  useEffect(()=>{
    loadArticles(0);
  },[])
  const loadArticles = async (actionType) => {
    if (
      !(
        actionType === 1 &&
        page + 1 >
          Math.ceil(totalResults / props.pageSize)
      )
    ) {
      topLoaderFunction(actionType,20)
      let pageNo =
        actionType === 0
          ? 1
          : actionType === 1
          ? page + 1
          : page - 1;
      let url = `https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=${props.apiKey}&page=${pageNo}&pageSize=${props.pageSize}`;
      // console.log(url);
      topLoaderFunction(actionType,40)
      //  setState({loading:true});
      // console.log(newsData);
      // return false;
      
      // let data = await fetch(url);
      let data = newsData;
      topLoaderFunction(actionType,60)
      // let parseData = await data.json();
      let parseData = data;
      topLoaderFunction(actionType,80)
      setArticles(actionType === 1
        ? articles.concat(parseData.articles)
        : parseData.articles);
        setLoading(false);
        setPage(pageNo);
        setTotalResults(parseData.totalResults)
      topLoaderFunction(actionType,100)
    }
  };
  const  fetchMoreData = async () => {
    loadArticles(1);
  };
  
    return (
      <>
        <h1 className="text-center mb-2" style={{margin:"35px 0px",marginTop:"90px"}}>
          NewsMonkey- Top {capitalizeFirstLetter(props.category)}{" "}
          Headlines
        </h1>
        {loading && <Spinner />}
        <InfiniteScroll
          dataLength={articles.length}
          next={fetchMoreData}
          // inverse={true} //
          hasMore={articles.length !== totalResults}
          loader={<Spinner />}
          // scrollableTarget="scrollableDiv"
        >
          <div className="container">
            <div className="row">
              {articles.map((newsRow) => {
                return (
                  newsRow.url && (
                    <div key={newsRow.url} className="col-md-4 my-1">
                      <NewsItem
                        newsUrl={newsRow.url}
                        title={newsRow.title ? newsRow.title.slice(0, 45) : ""}
                        description={
                          newsRow.description
                            ? newsRow.description.slice(0, 88)
                            : ""
                        }
                        imageUrl={newsRow.urlToImage}
                        author={newsRow.author}
                        publishedAt={newsRow.publishedAt}
                        source={newsRow.source.name}
                      />
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </InfiniteScroll>
      </>
    );
  
}
News.defaultProps = {
  country: "in",
  pageSize: 6,
  category: "general",
};
News.propTypes = {
  country: PropTypes.string,
  pageSize: PropTypes.number,
  category: PropTypes.string,
};
export default News;
